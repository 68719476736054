@font-face {
  font-family: Futura;
  src: url(../assets/fonts/FuturaBookfont.ttf);
}

@font-face {
  font-family: FuturaBold;
  src: url(../assets/fonts/Futura-Bold-font.ttf);
}

// @import "mixins";

@mixin hideScroll {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */

  &::-webkit-scrollbar {
    background: transparent;
    /* Chrome/Safari/Webkit */
    width: 0;
  }
}

body {
  margin: 0;
  margin: 0;
  padding: 0;
  // --green: #03a651;
  // background-color: var(--bg-blue);
  font-family: Futura;
  // background-color: #33146f;
  background-image: url(../assets/images/background.png);
  // background-color: #be0000;
  // background: url(../assets//images/completed-bg.png);
  // background: linear-gradient(
  //     90deg,
  //     #96181d 0%,
  //     #a51a20 8%,
  //     #ba1e25 22%,
  //     #c62128 36%,
  //     #cb2229 50%,
  //     #c62128 64%,
  //     #ba1e25 78%,
  //     #a51a20 92%,
  //     #96181d 100%
  //   ),
  //   #f3f2e8;

  background-size: 100% 100%;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.btn {
  // border-radius: 8px;
  // outline: none;
  // // border-radius: 32px;
  // // border: 4px solid #FFF;
  // // background: linear-gradient(143deg, #99212A 13.06%, #330B0E 142.52%), rgba(0, 0, 0, 0.70);
  // padding: 12px 45px;
  // font-size: 24px;
  // font-weight: 600;
  // text-align: center;
  // color: #fff;
  // cursor: pointer;

  &.btn-primary {
    background: #f68b1f;
    box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.23);
    // height: 48px;
    min-width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1em;
    position: absolute;
    // position: relative;
    font-family: FuturaBold;
    margin-top: 1rem;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    border: none;
    bottom: 0;

    @media (max-height: 600px) {
      height: 48px;
    }
  }

  &.back-btn {
    left: auto;
    transform: none;
    // margin-top: 1em;
    font-size: 15px;
    font-family: Futura;
  }
  &:disabled {
    cursor: not-allowed;
    touch-action: none;
    filter: grayscale(1);
  }
}

.gradient-text {
  // background: linear-gradient(90deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.22) 2%, rgba(255, 255, 255, 0.39) 5%, rgba(255, 255, 255, 0.53) 7%, rgba(255, 255, 255, 0.66) 10%, rgba(255, 255, 255, 0.77) 13%, rgba(255, 255, 255, 0.85) 16%, rgba(255, 255, 255, 0.92) 20%, rgba(255, 255, 255, 0.97) 25%, rgba(255, 255, 255, 0.99) 31%, #FFF 50%, rgba(255, 255, 255, 0.99) 69%, rgba(255, 255, 255, 0.97) 75%, rgba(255, 255, 255, 0.92) 80%, rgba(255, 255, 255, 0.85) 84%, rgba(255, 255, 255, 0.77) 87%, rgba(255, 255, 255, 0.66) 90%, rgba(255, 255, 255, 0.53) 93%, rgba(255, 255, 255, 0.39) 95%, rgba(255, 255, 255, 0.22) 98%, rgba(255, 255, 255, 0.00) 100%);
  // background-blend-mode: overlay;
  background: rgb(37, 51, 108);
  background: linear-gradient(
    90deg,
    rgba(37, 51, 108, 0.36017734730610995) 6%,
    rgba(51, 70, 149, 1) 50%,
    rgba(37, 51, 108, 0) 94%
  );
  color: #fff;
  padding: 0.5rem;
  // width: 100vw;
  width: 100%;
  &.cashback-amt {
    font-size: 24px;
    margin-bottom: 0;
    font-weight: bold;
  }
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
  text-align: center;
  color: #fff;
  font-family: Futura;

  // font-style: italic;
  .link {
    text-decoration: underline;
    cursor: pointer;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ {
        .checkmark {
          // background-color: white;
          &:after {
            display: block;
          }
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    // background: #fff;
    border: 1.6px solid #fff;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 0px;
      width: 4px;
      height: 9px;
      border: solid #ffbc1f;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}

select {
  padding: 12px;
  font-size: 13px;
  // text-align: center;
  text-align: left;
  border: none;
  box-sizing: border-box;
  font-family: Futura;
  background: transparent;
  // background: #ffff;
  border-radius: 8px;
  border: 1px solid #ffbc1f;
  color: #000;
  width: 100%;
  outline: none;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  appearance: none;

  &::placeholder {
    color: #454545;
    font-family: Futura;
  }
}

.checkbox-container1 {
  // display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  text-align: start;
  color: #fff;
  font-family: Futura;

  .link {
    text-decoration: underline;
    cursor: pointer;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ {
        .checkmark {
          // background-color: white;
          &:after {
            display: block;
          }
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    // border: 1px solid #858585;
    // background: #fff;
    background: transparent;
    border: 1.6px solid #f3f2e8;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 0px;
      width: 4px;
      height: 9px;
      // border: solid #ed1c24;
      border: solid #ffbc1f;

      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}

// .checkmark-smoke {
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 18px;
//   width: 18px;
//   border-radius: 2px;
//   border: 1px solid #858585;
//   background: #fff;
//   &:after {
//     content: "";
//     position: absolute;
//     display: none;
//     left: 4px;
//     top: 0px;
//     width: 4px;
//     height: 9px;
//     border: solid #ed1c24;
//     border-width: 0 2px 2px 0;
//     transform: rotate(45deg);
//   }
// }

.input-group {
  margin-bottom: 20px;
  text-align: center;
  margin: 0 auto 12px;
  //not a robot
}

.input-label {
  color: #fff;
  float: left;
  font-size: small;
  padding-bottom: 1px;
  // padding-bottom: 0.5em;
}

.error {
  color: #ffba12;
  text-align: center;
  font-family: Futura;
  font-size: 12px;
}

input {
  padding: 12px;
  font-size: 13px;
  text-align: center;
  border: none;
  box-sizing: border-box;
  font-family: Futura;
  background: transparent;
  // background: #ffff;
  border-radius: 4px;
  // border: 2px solid #cc9465;
  border-bottom: 2px solid #261b62;
  color: #000;
  width: 100%;
  outline: none;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  height: 48px;

  &::placeholder {
    color: #454545;
    font-family: Futura;
  }
}

@media (max-height: 600px) {
  .register-page {
    .register-form,
    .otp-form {
      .input-group {
        input {
          height: 36px !important;
          /* Adjust the height as needed */
        }
      }
    }
  }
}

.grecaptcha-badge {
  bottom: 40px !important;
}

.Toastify__toast-theme--light {
  // background: var(--bg-red) !important;
  // color: white !important;
  &.Toastify__toast--success {
    background: var(--bg-blue);
  }
}

.App {
  height: 100svh;
  // height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  @media screen and (min-width: 768px) {
    display: block;
    margin: 0 auto;
    max-width: 375px;
    @include hideScroll;
  }
}

// .flag {
//   width: 100%;
//   height: 40px;
//   // animation: flagAnim 100s linear infinite;
//   flex-shrink: 0;
//   background-position: bottom;
//   background-repeat: repeat-x;
//   background-size: contain;
//   &.top {
//     background-image: url(../assets/images/anim/Flag-Animation.gif);
//     transform: rotateZ(180deg);
//   }
//   &.bottom {
//     background-image: url(../assets/images/anim/Flag-Animation.gif);
//   }
// }

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  .popup-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.7);
  }

  .popup-modal {
    width: 100%;
    position: relative;
    z-index: 1;
    background-color: white;
    // width: 80%;
    max-width: 400px;
    border-radius: 0.5rem;
    max-height: 85vh;
    overflow: auto;
    font-family: Futura;

    .header {
      width: 100%;
      height: 40px;
      border-bottom: 1px solid lightgray;
      position: sticky;
      top: 0;
      background-color: white;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .title {
        font-size: 20px;
        margin: 0;
      }
    }

    .content {
      padding: 1rem;
      text-align: center;
    }

    .closeBtn {
      position: absolute;
      top: 10px;
      width: 22px;
      right: 10px;
      margin: auto;
      cursor: pointer;

      &:hover {
        transform: scale(1.02);
      }
    }
  }
}

.dropdown {
  z-index: 5;
  background-color: #fff;
  position: absolute;
  width: 73%;
  display: flex;
  overflow: scroll;
  overflow-x: hidden;
  max-height: 20vh;
  min-height: 5vh;
  left: 13%;
  border-radius: 5px;
  -webkit-box-shadow: 0px 6px 15px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 6px 15px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 6px 15px -3px rgba(0, 0, 0, 0.75);
  color: #000;

  ul {
    text-align: start;
    padding: 1rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 2vh;
    width: 100%;

    @media (min-width: 768px) {
      padding: 1rem;
    }

    li {
      list-style-type: none;
      padding: 0.5vh 1vw;
      border-radius: 5px;

      &:hover {
        background-color: #eb7426;
      }
    }

    .nf {
      pointer-events: none;
    }
  }
}
