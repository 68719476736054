@import "../Register/Register";

.watch-video-page {
  .video-container {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
    padding: 0;
    width: 90%;
    position: relative;

    video {
      border-radius: 8px;
      // margin-bottom: 20px;
    }
    .play-button {
      position: absolute;
      // top: 50%;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      z-index: 10;
      display: block;
      width: 10%;
    }
    

    .video-text {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: center;
      color: #fff;
    }

    .next-button {
      padding: 10px 20px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}
